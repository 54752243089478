<template>
  <div>
    <!-- 面包屑区域 -->
    <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card shadow="hover">
      <div class="content_top">
        <Title :title="title[0]"></Title>
        <div class="classification">
          <el-button type="primary" v-for="(item,index) in classification" :key="item.id" class="classification_btns" :plain="classificationIndex==index ? false : true" @click="changeClassification(index)">{{item.text}}</el-button>
        </div>
      </div>

      <FirstModule ref="firstModule" v-if="classificationIndex==0" :otherInfo="otherInfo" :advancePaymentInfo="advancePaymentInfo"></FirstModule>
      <SecondModule ref="secondModule" v-else-if="classificationIndex==1" :otherInfo="otherInfo" :advancePaymentInfo="advancePaymentInfo"></SecondModule>
      <ThirdModule ref="thirdModule"  v-else :otherInfo="otherInfo" :advancePaymentInfo="advancePaymentInfo"></ThirdModule>

    
    </el-card>


  </div>
</template>

<script> 
import { mapState } from 'vuex'
import FirstModule from './First_Module/firstModule'
import SecondModule from './Second_Module/secondModule'
import ThirdModule from './Third_Module/thirdModule'
export default {
  data() {
    return {
      title: ["垫付任务管理"],
      //面包屑数据
      breadcrumbData: [
        {
          id: 1,
          title: "好物电商",
          path: "",
          isClick: false
        },
        {
          id: 2,
          title: "任务管理",
          path: "",
          isClick: false
        },
        {
          id: 3,
          title: "垫付任务管理",
          path: "advancepayment",
          isClick: true
        }
      ],
      classification: [
        {
          id: 1,
          text: "垫付任务管理"
        },
        {
          id: 2,
          text: "垫付进行中订单"
        },
        {
          id: 3,
          text: "垫付历史订单"
        }
      ],
      //选中分类索引
      classificationIndex: 0,
    
    
     
    }
  },
  computed:{
       ...mapState({
           otherInfo:state => state.advancePayment.otherInfo,
           advancePaymentInfo:state => state.advancePayment.advancePaymentInfo
       })
  },
  components: {
    FirstModule,
    SecondModule,
    ThirdModule
  },
  methods: {
    //切换分类
    changeClassification(index,object) {
      this.classificationIndex = index;
      let classification = index + 1
      if(index==0){
             this.$nextTick(()=>{
                const { pagesize,pagenum } = this.$refs.firstModule.queryInfo;
                this.$store.dispatch('getAdvancePaymentInfo',{classification,num:pagesize,page:pagenum,...object})
             })
      }else if(index==1){
             this.$nextTick(()=>{
                 const { pagesize,pagenum } = this.$refs.secondModule.queryInfo;
                 this.$store.dispatch('getAdvancePaymentInfo',{classification,num:pagesize,page:pagenum,...object})
             })
      }else{
             this.$nextTick(()=>{
                 const { pagesize,pagenum } = this.$refs.thirdModule.queryInfo;
                 this.$store.dispatch('getAdvancePaymentInfo',{classification,num:pagesize,page:pagenum,...object})
             })
      }
    },

    //获取店铺列表、平台列表、订单状态
    getOtherInfo(){
         this.$store.dispatch('getOtherInfo')
    },

    //获取垫付任务信息
    getAdvancePaymentInfo(){
       const { classificationIndex } = this
       const { pagesize,pagenum } = this.$refs.firstModule.queryInfo
       let classification = classificationIndex + 1
       this.$store.dispatch('getAdvancePaymentInfo',{classification,num:pagesize,page:pagenum})
    },

  
  },
  mounted(){
      this.getOtherInfo()
      this.getAdvancePaymentInfo()
  }
};
</script>

<style lang="less" scoped>
.el-card {
  margin: 20px 20px 50px 20px;
}
.content_top {
  .classification {
    margin-top: 20px;
    padding: 0 30px;
    .classification_btns {
      margin-right: 50px;
      width: 160px;
      height: 38px;
      font-size:15px;
    }
  }
}

</style>