<template>
  <div class="third_module">
    <div class="search_box">
      <div class="search_item">
        <span class="tit">选择店铺：</span>
        <el-select v-model="storevalue" placeholder="请选择" class="kd">
          <el-option
            v-for="item in otherInfo.storeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="search_item">
        <span class="tit">提交时间：</span>
        <el-date-picker
          v-model="timeValue"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </div>

      <div class="search_item">
        <span class="tit">关键词：</span>
        <el-input placeholder="请输入关键词" v-model="keyWord" class="kd"></el-input>
      </div>

      <div class="search_item">
        <el-button type="primary" @click="search">搜索</el-button>
      </div>

    </div>

    <!-- 列表区域  border加边框 stripe隔行变色 -->
    <div class="table">
         <el-table :data="advancePaymentInfo.info" border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}">
          <el-table-column label="店铺" prop="storename"></el-table-column>
          <el-table-column label="接单时间" prop="ctime"></el-table-column>
          <el-table-column label="完成时间" prop="stime"></el-table-column>
          <el-table-column label="订单编号" prop="orderid"></el-table-column>
          <el-table-column label="任务ID" prop="taskbh"></el-table-column>
          <el-table-column label="接单账号" prop="userid"></el-table-column>
          <el-table-column label="实付金额/元" prop="money"></el-table-column>
          <el-table-column label="订单状态">
                  <template v-slot:default="scope">
                     <div>已完成</div>
                     <el-button type="primary" size="small" @click="viewSubmissionDetails(scope.row.id)" style="width:80px;margin-top:5px">查看详情</el-button>
                  </template>
          </el-table-column>
          <el-table-column label="商品主图" prop="goodimg">
                   <template v-slot:default="scope">
                      <el-image v-for="image in scope.row.goodimg" :key="image.id" :src="image.url" style="width:80px; height:80px;margin-right:5px" fit="fill" :preview-src-list="masterImageList"  @click="getMasterPreviewImage(scope.row.goodimg)"></el-image>
                  </template>
          </el-table-column>
        </el-table>
    </div>

     <!-- 分页区域 -->
      <el-pagination
        :current-page="queryInfo.pagenum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="advancePaymentInfo.page_arr"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="advancePaymentInfo.num"
      ></el-pagination>

    <!-- 查看提交详情对话框 -->
    <el-dialog title="买手提交的任务详情" :visible.sync="submissionDetailsDialogVisible" width="50%" center>
          <div class="submission_details">
               <div class="details_bh">
                    订单编号：<span class="bh">{{viewSubmissionInfo.taskbh}}</span>
               </div>
               <div class="details_nr">
                   <div class="content" v-for="item in viewSubmissionInfo.img" :key="item.id">
                          <div class="title">{{item.text}}</div>
                          <el-image :src="item.img" style="width:220px; height: 400px"  fit="fill" lazy class="picture" :preview-src-list="submissionDetailsImageList"  @click="previewImage(viewSubmissionInfo.img)"></el-image>
                   </div>
               </div>
          </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from  'vuex'
export default {
  data() {
    return {
      //获取任务列表的参数对象
      queryInfo: {
        // 当前的页数
        pagenum: 1,
        // 当前每一页显示多少条数据
        pagesize: 10
      },
      //店铺列表
      storeOptions: [
        {
          value: "1",
          label: "名望香业"
        },
        {
          value: "2",
          label: "谈香论道"
        },
        {
          value: "3",
          label: "春雪送信"
        },
        {
          value: "4",
          label: "闻香识味"
        },
        {
          value: "5",
          label: "香木阁主"
        }
      ],
      storevalue: "",
      //平台列表
      platformOptions: [
        {
          value: "1",
          label: "淘宝"
        },
        {
          value: "2",
          label: "京东"
        },
        {
          value: "3",
          label: "拼多多"
        },
        {
          value: "4",
          label: "抖音"
        }
      ],
      platformValue: "",
      //订单状态列表
      orderStatusOptions: [
        {
          value: "1",
          label: "全部"
        },
        {
          value: "2",
          label: "订单待提交"
        },
        {
          value: "3",
          label: "订单待确认"
        },
        {
          value: "4",
          label: "待上传快递"
        },
        {
          value: "5",
          label: "待上传好评"
        },
        {
          value: "6",
          label: "好评待确认"
        },
        {
          value: "7",
          label: "订单未通过审核"
        }
      ],
      orderStatusValue: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      //买家提交详情
      submissionDetailsDialogVisible:false,
      //预览图片列表
      masterImageList:[],
      //提交详情预览图片集合
      submissionDetailsImageList:[],
      //时间
      timeValue:[],
      //关键词
      keyWord:''
    };
  },
  props:['otherInfo','advancePaymentInfo'],
  computed:{
     ...mapState({
         viewSubmissionInfo:state => state.advancePayment.viewSubmissionInfo,
     })
  },
  methods:{
      //监听pagesize改变的事件
      handleSizeChange(newSize) {
          this.queryInfo.pagesize = newSize;
          this.queryInfo.pagenum = 1;
          this.$parent.$parent.changeClassification(2)
      },
    
      //监听页码值改变的事件
      handleCurrentChange(newPage) {
          this.queryInfo.pagenum = newPage;
          this.$parent.$parent.changeClassification(2)
      },

      //查看提交详情
      async viewSubmissionDetails(id){
            try{
               await this.$store.dispatch('viewSubmissionDetails',id) 
               this.submissionDetailsDialogVisible = true
            }catch(error){
               this.$message.error(error.message)
            }           
      },
  
      //获取预览图片集
      getMasterPreviewImage(list){
             let imageList = []
             list.forEach(item => {
                  imageList.push(item.url)
             });
             this.masterImageList = imageList
      },

      //获取提交详情预览图片集合
      previewImage(list){
             let imageList = []
             list.forEach(item => {
                  imageList.push(item.img)
             });
             this.submissionDetailsImageList = imageList
      },

      
      //搜索
      search(){
           const {storevalue,timeValue,keyWord} = this
           let object = {}
           object.store = storevalue
           object.key = keyWord
           if(this.timeValue != null){//解决clear存在的问题
               object.stime = this.timeValue[0]
               object.etime = this.timeValue[1]
           }else{
               this.timeValue = []
               object.stime = this.timeValue[0]
               object.etime = this.timeValue[1]
           }
           this.$parent.$parent.changeClassification(2,object)
      }
  }
};
</script>

<style lang="less" scoped>
.third_module {
  margin-top: 30px;
  .search_box {
    padding: 0 0 0 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .search_item {
      margin-right: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.kd {
  width: 200px;
}

.table{
    margin:30px 30px 20px;
}

.submission_details{
     .details_bh{
          margin-bottom:10px;
          .bh{
              color:#FFAC1C
          }
     }
     .details_nr{
        display:flex;
        justify-content:flex-start; 
        align-items:center;
        flex-wrap:wrap;
        margin-top:20px;
       .content{
          display:flex;
          flex-direction:column;
          justify-content:flex-start;
          margin-right:15px;
          margin-bottom:30px;
          width:250px;
          .title{
              font-size:16px
          }
         .picture{
              // width: 50%;
              // height:50%;
          }
        }
     }
  
}
.el-pagination{
   padding:0 30px;
   margin-bottom:30px;
}
</style>