<template>
  <div class="first_module">
    <div class="search_box">
      <div class="search_item">
        <span class="tit">选择店铺：</span>
        <el-select v-model="storevalue" placeholder="请选择" class="kd">
          <el-option
            v-for="item in otherInfo.storeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="search_item">
        <span class="tit">选择平台：</span>
        <el-select v-model="platformValue" placeholder="请选择" class="kd">
          <el-option
            v-for="item in otherInfo.platformOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="search_item">
        <span class="tit">订单状态：</span>
        <el-select v-model="orderStatusValue" placeholder="请选择" class="kd">
          <el-option
            v-for="item in otherInfo.orderStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="search_item">
        <span class="tit">任务编号：</span>
        <el-input placeholder="请输入任务编号" v-model="taskBh" class="kd"></el-input>
      </div>

      <div class="search_item">
        <span class="tit">商品名称：</span>
        <el-input placeholder="请输入商品名称" v-model="commodityName" class="kd"></el-input>
      </div>

      <div class="search_item">
        <span class="tit">时间：</span>
        <el-date-picker
          v-model="timeValue"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </div>

      <div class="search_item">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="primary" @click="exportOrders(1)">导出所有订单</el-button>
        <span class="tips">只保留最近2个月数据</span>
      </div>
    </div>

    <div class="tip_box">
      <div class="tip_tit">
        <i class="haowu hw-tishi_02 tips_icon"></i>
        <span class="tip_t">温馨提示</span>
      </div>
      <div class="tip_content">
        <p class="tip">（1）平台待确认任务确认后系统将会返款佣金给用户，请商家尽快确认；如未及时确认，系统将于24小时自动返款佣金;</p>
        <p class="tip">（2）平台待返款发货任务确认后系统将会返款本金给用户，请商家仔细核对确认；如未及时确认，系统将于24小时自动返款本金；</p>
        <p
          class="tip"
        >（3）部分买手存在已付款订单但平台未及时提交造成系统超时撤销订单，请商家务必到 已撤销订单-买手撤销订单 找到系统超时撤销订单主动进行备注避免发货出去造成不必要麻烦</p>
        <p
          class="tip tip_s"
        >（4）用户未按照设置本金下单的，系统将不再自动过审，需商家手动审核，请及时去审核，以免时间过长导致用户申请退款,如超过48小时未审核，转入平台客服手动审核</p>
      </div>
    </div>

    <div class="list_box">
      <div class="list_item" v-for="item in advancePaymentInfo.info" :key="item.id">
        <div class="item_top">
          <span class="left_store_name">{{item.shopsname}}</span>
          <div class="right_details">
            <span class="d_item task_type">{{item.tasktype}}</span>
            <span class="d_item order">
              总单数：
              <font class="num">{{item.goodsnum}}</font>
            </span>
            <span class="d_item bh">活动ID：{{item.bh}}</span>
            <span class="d_item operation" @click="exportOrders(2)">[ 分类导出订单 ]</span>
            <span class="d_item operation" @click="viewDetails(item.bh)">[ 查看详情 ]</span>
            <span class="d_item operation">[ 再次发布 ]</span>  
            <span class="d_item operation" v-if="item.type==2||item.type==4" @click="deleteTask(item.bh)">[ 删除任务 ]</span>
            <span class="d_item operation" v-if="item.type==2">[ 编辑 ]</span>
            <span class="d_item topay" v-if="item.type==2" @click="toPay(item.bh)">[去支付]</span>
            
            <span class="d_item waiting_for_payment" v-if="item.type==2">待支付</span>
            <span class="d_item under_review" v-if="item.type==1">审核中</span>
            <span class="d_item underway" v-if="item.type==3">进行中</span>
            <span class="d_item audit_failed" v-if="item.type==4">审核失败</span>
            <span class="d_item end" v-if="item.type==5">已完成</span>
            <span class="d_item cancelled" v-if="item.type==7">已取消</span>

            <span class="d_item settlement" v-if="item.type==3||item.type==6" @click="handle(1,item.bh)">结算</span>
            <span class="d_item suspend" v-if="item.type==3" @click="handle(2,item.bh)">暂停</span>
            <span class="d_item suspend" v-if="item.type==6" @click="handle(3,item.bh)">继续</span>
          </div>
        </div>
        <div class="item_bottom">
          <div class="b_left">
            <div class="commodity_picture">
              <img :src="item.goodsimg" class="picture" />
            </div> 
            <div class="commodity_details">{{item.goodsname}}</div>
          </div>
          <div class="b_middle">
            <div class="time_box">
              <span class="tit">时间：</span>
              <span class="time">{{item.ctime}}</span>
            </div>
          </div>
          <div class="b_right">
            <div class="received_order">
              <span class="num">{{item.haveorder}}</span>
              <span class="text">已接单</span>
            </div>
            <div class="to_be_confirmed">
              <span class="num">{{item.waitorder}}</span>
              <span class="text">待确认</span>
            </div>
            <div class="completed">
              <span class="num">{{item.isorder}}</span>
              <span class="text">已完成</span>
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- 分页区域 -->
      <el-pagination
        :current-page="queryInfo.pagenum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="advancePaymentInfo.page_arr"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="advancePaymentInfo.num"
      ></el-pagination>

     <!-- 导出订单对话框 -->
    <el-dialog :title="exportData.exportTitle" :visible.sync="exportData.exportOrdersDialogVisible" width="30%" center>
      <!--内容主体区域-->
      <el-form status-icon  label-width="110px">

        <el-form-item label="请选择店铺" v-if="exportData.storeStatus">
          <el-select  placeholder="请选择店铺" v-model="exportData.exportStorevalue">
            <el-option v-for="item in exportData.exportStoreOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

       <el-form-item label="订单时间">
             <el-date-picker v-model="exportData.exportTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>

         <el-form-item label="订单状态">
               <el-select v-model="exportData.orderStatusValue" multiple placeholder="请选择订单状态">
                     <el-option v-for="item in exportData.orderStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
               </el-select>
        </el-form-item>

      </el-form>
      <!--底部区域-->
      <span slot="footer" class="dialog-footer">
         <el-button type="primary" class="haowu hw-queren"> 确定提交</el-button>
         <el-button @click="exportData.exportOrdersDialogVisible = false"  type="danger" class="haowu hw-quxiao"> 取消退出</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default { 
  data() {
    return {
      //店铺列表
      storeOptions: [
        {
          value: "1",
          label: "名望香业"
        },
        {
          value: "2",
          label: "谈香论道"
        },
        {
          value: "3",
          label: "春雪送信"
        },
        {
          value: "4",
          label: "闻香识味"
        },
        {
          value: "5",
          label: "香木阁主"
        }
      ],
      storevalue: "",
      //平台列表
      platformOptions: [
        {
          value: "1",
          label: "淘宝"
        },
        {
          value: "2",
          label: "京东"
        },
        {
          value: "3",
          label: "拼多多"
        },
        {
          value: "4",
          label: "抖音"
        }
      ],
      platformValue: "",
      //订单状态列表
      orderStatusOptions: [
        {
          value: "1",
          label: "未支付"
        },
        {
          value: "2",
          label: "待审核"
        },
        {
          value: "3",
          label: "进行中"
        },
        {
          value: "4",
          label: "审核失败"
        },
        {
          value: "5",
          label: "取消中"
        },
        {
          value: "6",
          label: "待结算"
        },
        {
          value: "7",
          label: "已结束"
        }
      ],
      orderStatusValue: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      
      //获取任务列表的参数对象
      queryInfo: {
        // 当前的页数
        pagenum: 1,
        // 当前每一页显示多少条数据
        pagesize: 10
      },
      total:100,

      //导出数据
      exportData: {
        //导出订单对话框
        exportOrdersDialogVisible: false,
        //导出对话框标题
        exportTitle: "",
        //控制导出店铺列表的显示与隐藏
        storeStatus: true,
        //店铺列表
        exportStoreOptions: [
          {
            value: "1",
            label: "名望香业"
          },
          {
            value: "2",
            label: "谈香论道"
          },
          {
            value: "3",
            label: "春雪送信"
          },
          {
            value: "4",
            label: "闻香识味"
          },
          {
            value: "5",
            label: "香木阁主"
          }
        ],
        exportStorevalue: "全部",

        //导出时间
        exportTime: "",

        //审核状态列表
        orderStatusList: [
          {
            value: "1",
            label: "全部"
          },
          {
            value: "2",
            label: "已放弃"
          },
          {
            value: "3",
            label: "待审核"
          },
          {
            value: "4",
            label: "已审核"
          },
          {
            value: "5",
            label: "已上传快递"
          },
          {
            value: "6",
            label: "待好评"
          },
          {
            value: "7",
            label: "已完成"
          },
          {
            value: "8",
            label: "驳回"
          }
        ],
        orderStatusValue: []
      },

      //任务编号
      taskBh:'',
      //商品名称
      commodityName:'',
      //时间
      timeValue:[],
    };
  },
  props:['otherInfo','advancePaymentInfo'],
  methods:{   
    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.queryInfo.pagenum = 1;
      this.$parent.$parent.getAdvancePaymentInfo()
    },
    
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.$parent.$parent.getAdvancePaymentInfo()
    },
    
    //删除未支付或审核失败的任务
    deleteTask(bh){
          // 打开提示弹窗
          this.$confirm('是否确认删除该任务？', '提示', {
               distinguishCancelAndClose: true,
               confirmButtonText: '删除',
               cancelButtonText: '取消',
               type: 'warning',
               center: true
           })
          .then(async () => {
               try{
                    await this.$store.dispatch('deleteTask',bh)
                    this.$message.success("删除成功！");
                    this.$parent.$parent.getAdvancePaymentInfo()
               }catch(error){
                    this.$message.error(error.message);
               }
          })
          .catch(action => {
          });
    },

    //搜索
    search(){
        const {storevalue,platformValue,orderStatusValue,taskBh,commodityName } = this
        let object = {}
        object.store = storevalue
        object.platform = platformValue
        object.state = orderStatusValue
        object.bh = taskBh
        object.shopname = commodityName
        if(this.timeValue != null){//解决clear存在的问题
            object.stime = this.timeValue[0]
            object.etime = this.timeValue[1]
        }else{
            this.timeValue = []
            object.stime = this.timeValue[0]
            object.etime = this.timeValue[1]
        }
        this.$parent.$parent.changeClassification(0,object)
    },

    //去支付
    toPay(bh){
         this.$router.push({path:'/settlement',query:{bh}})
    },

    //处理结算暂停继续业务
    handle(val,bh){
         if(val==1){//结算
                // 打开提示弹窗
                this.$confirm('确认结算'+bh+'此任务？', '提示', {
                     closeOnClickModal:false,
                     distinguishCancelAndClose: true,
                     confirmButtonText: '结算',
                     cancelButtonText: '取消',
                     type: 'warning',
                     center: true
                 })
                .then(async () => {
                     try{
                          await this.$store.dispatch('handle',{taskbh:bh,state:val})
                          this.$message.success("结算成功！");
                          this.$parent.$parent.getAdvancePaymentInfo()
                     }catch(error){
                          this.$message.error(error.message);
                     }
                })
                .catch(action => {
                });
         }else if(val==2){//暂停
                 // 打开提示弹窗
                this.$confirm('确认暂停'+bh+'此任务？', '提示', {
                     closeOnClickModal:false,
                     distinguishCancelAndClose: true,
                     confirmButtonText: '暂停',
                     cancelButtonText: '取消',
                     type: 'warning',
                     center: true
                 })
                .then(async () => {
                     try{
                          await this.$store.dispatch('handle',{taskbh:bh,state:val})
                          this.$message.success("已暂停！");
                          this.$parent.$parent.getAdvancePaymentInfo()
                     }catch(error){
                          this.$message.error(error.message);
                     }
                })
                .catch(action => {
                });

         }else{//继续
                 // 打开提示弹窗
                this.$confirm('确认继续'+bh+'此任务？', '提示', {
                     closeOnClickModal:false,
                     distinguishCancelAndClose: true,
                     confirmButtonText: '继续',
                     cancelButtonText: '取消',
                     type: 'warning',
                     center: true
                 })
                .then(async () => {
                     try{
                          await this.$store.dispatch('handle',{taskbh:bh,state:val})
                          this.$message.success("已继续！");
                          this.$parent.$parent.getAdvancePaymentInfo()
                     }catch(error){
                          this.$message.error(error.message);
                     }
                })
                .catch(action => {
                });

         }

    },

    //查看详情
    viewDetails(bh){
        this.$router.push({path:'/taskdetails',query:{bh}})
    },

    //导出订单
    exportOrders(val){
          if(val==1){
                this.exportData.exportTitle = '分类导出所有订单'
                this.exportData.exportOrdersDialogVisible = true

          }else{
                this.exportData.exportTitle = '分类导出订单'
                this.exportData.storeStatus = false
                this.exportData.exportOrdersDialogVisible = true
          }
    }
  }
};
</script>

<style lang="less" scoped>
@assets:"~@/assets";
@import "@{assets}/css/theme.less";
.first_module {
  margin-top: 30px;
  .search_box {
    padding: 0 0 0 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .search_item {
      margin-right: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .tip_box {
    height: auto;
    // border: 1px solid #ff9940;
    // border: 1px solid @themeColor;
    background-color:@themebgColor;
    border-radius: 10px;
    margin: 20px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
    .tip_tit {
      width: 200px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .tips_icon {
        font-size: 30px;
        color: #e98f36;
        margin-right: 10px;
      }
      .tip_t {
        font-size: 22px;
      }
    }
    .tip_content {
      margin-left: 10px;
      .tip {
        font-size: 12px;
        color: #a2a2a2;
        margin-top: 5px;
      }
    }
  }
  .list_box {
    margin: 50px 30px 15px 30px;
    height: auto;
    .list_item {
      width: 100%;
      height: auto;
      //  background:#f1f1f1;
      border-bottom: 1px solid #ebeef5;
      padding: 10px 0;
      .item_top {
        height: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px 0 10px;
        .left_store_name {
          font-size: 16px;
          font-weight: bold;
        }
        .right_details {
          display: flex;
          justify-self: start;
          align-items: center;
          .d_item {
            margin-right: 20px;
          }
          .task_type {
            color: #4165f2;
          }
          .order {
            color: #a2a2a2;
            .num {
              color: #ff9940;
              font-size: 20px;
              font-weight: 700;
            }
          }
          .bh {
            color: #a2a2a2;
          }
          .end {
            color: #18a86d;
            font-weight: 600;
          }
          .operation {
            color: #465669;
            cursor: pointer;
          }
          .topay {
            color: #4d92ff;
            font-weight: 600;
            cursor: pointer;
          }
          .waiting_for_payment {
            color: #ff9940;
            font-weight: 600;
          }
          .under_review{
             color: #9055FF;
             font-weight: 600;
          }
          .audit_failed{
             color: #FF4500;
             font-weight: 600;
          }
          .cancelled{
             color: #409EFF;
             font-weight: 600;
          }
          .underway{
             color: #06BC58;
             font-weight: 600;
          }
          .settlement{
             width:40px;
             height:20px;
             background-color: #FF4500;
             border-radius:5px;
             text-align: center;
             color: #ffffff;
             box-shadow:0px 0px 5px #888888;;
             font-weight: 600;
             font-size:14px;
             cursor: pointer;
             margin-right:5px;
          }
          .suspend{
             width:40px;
             height:20px;
             background-color: #FF4500;
             border-radius:5px;
             text-align: center;
             color: #ffffff;
             box-shadow:0px 0px 5px #888888;;
             font-weight: 600;
             font-size:14px;
             cursor: pointer;
          }
        }
      }
      .item_bottom {
        color: #ff9940;
        height: 100px;
        margin-top: 5px;
        padding: 0 10px 0 10px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .b_left {
          width: 400px;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .commodity_picture {
            width: 100px;
            height: 100px;
            border-radius: 8px;
            overflow: hidden;
            .picture {
              width: 100px;
              height: 100px;
              border-radius: 8px;
            }
          }
          .commodity_details {
            width: 250px;
            height: 100%;
            margin-left: 20px;
            overflow: hidden;
          }
        }
        .b_middle {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .tit {
            color: #a2a2a2;
          }
          .time_box {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .b_right {
          height: 100%;
          display: flex;
          justify-self: start;
          align-items: center;
          .received_order {
            width: 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            color: #606266;
            font-size: 16px;
            .text {
              margin-top: 20px;
            }
          }
          .to_be_confirmed {
            width: 80px;
            border-left: 1px solid #dcdcdc;
            border-right: 1px solid #dcdcdc;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            color: #ff9940;
            font-size: 16px;
            .text {
              margin-top: 20px;
            }
          }
          .completed {
            width: 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            color: #606266;
            font-size: 16px;
            .text {
              margin-top: 20px;
            }
          }
        }
      }
    }
    .list_item:nth-child(1) {
      border-top: 1px solid #ebeef5;
    }
  }
}

.kd {
  width: 200px;
}
.tips {
  color:@thirdFontColor;
  margin-left: 20px;
}
.tip_s{
  color:@thirdFontColor !important;
}
.el-pagination{
   padding:0 30px;
   margin-bottom:30px;
}
</style>