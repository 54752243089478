<template>
  <div class="second_module">
    <div class="search_box">
      <div class="search_item">
        <span class="tit">选择店铺：</span>
        <el-select v-model="storevalue" placeholder="请选择" class="kd">
          <el-option
            v-for="item in otherInfo.storeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="search_item">
        <span class="tit">提交时间：</span>
        <el-date-picker
          v-model="timeValue"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </div>

      <div class="search_item">
        <span class="tit">条件搜索：</span>
        <el-input placeholder="请输入搜索条件" v-model="condition" class="kd"></el-input>
      </div>

       <div class="search_item">
        <span class="tit">订单状态：</span>
        <el-select v-model="orderStatusValue" placeholder="请选择" class="kd">
          <el-option
            v-for="item in otherInfo.orderStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="search_item">
        <el-button type="primary" @click="search">搜索</el-button>
      </div>
    </div>
    <p class="tips">用户未按照设置本金下单的，系统将不再自动过审，需商家手动审核，请及时去审核，以免时间过长导致用户申请退款,如超过48小时未审核，转入平台客服手动审核</p>

    <!-- 列表区域  border加边框 stripe隔行变色 -->
    <div class="table">
        <el-table :data="advancePaymentInfo.info"  border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}">
          <el-table-column label="店铺" prop="storename"></el-table-column>
          <el-table-column label="接单时间" prop="ctime"></el-table-column>
          <el-table-column label="交易平台订单" prop="orderid"></el-table-column>
          <el-table-column label="活动ID" prop="taskbh"></el-table-column>
          <el-table-column label="快递单号" prop="courier"></el-table-column>
          <el-table-column label="接单账号" prop="userid">
               <template v-slot:default="scope">
                      <p>{{scope.row.userid}}</p>
                      <el-button v-if="scope.row.userhei==1" type="danger" size="small" round style="margin-top:5px;background-color:#FF4500;border:1px solid #FF4500" @click="joinTheBlacklist(scope.row.userid)">拉黑</el-button>
                      <p v-else style="color:#FF4500;font-weight:800">已拉黑</p>
               </template>
          </el-table-column>
          <el-table-column label="实付金额/元" prop="money"></el-table-column>
          <el-table-column label="订单状态" prop="statename"></el-table-column>
          <el-table-column label="好评图片" prop="goodimg">
                <template v-slot:default="scope">
                      <el-image v-for="image in scope.row.goodimg" :key="image.id" :src="image.url" style="width:50px; height:50px;margin-right:5px" fit="fill" :preview-src-list="praiseImageList"  @click="getPraisePreviewImage(scope.row.goodimg)"></el-image>
               </template>
          </el-table-column>
          <el-table-column label="操作">
              <template v-slot:default="scope">
                   <div class="operation_btns">
                           <el-button type="primary" v-if="scope.row.state==4" size="small" @click="viewSubmissionDetails(scope.row.id)" style="width:80px">查看详情</el-button>
                           <el-button type="warning" v-if="scope.row.state==4"  size="small" @click="urgeEvaluation(scope.row.taskbh)" style="margin-top:5px;width:80px;margin-left:0">催评</el-button>
                           <el-button type="primary" v-if="scope.row.state==5"  size="small" @click="viewReviews(scope.row.id,scope.row.taskbh)" style="width:80px">审核好评</el-button>
                   </div>
              </template>
          </el-table-column>
        </el-table>
    </div>

      <!-- 分页区域 -->
      <el-pagination
        :current-page="queryInfo.pagenum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="advancePaymentInfo.page_arr"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="advancePaymentInfo.num"
      ></el-pagination>

     <!-- 查看提交详情对话框 -->
    <el-dialog title="买手提交的任务详情" :visible.sync="submissionDetailsDialogVisible" width="50%" center>
          <div class="submission_details">
               <div class="details_bh">
                    订单编号：<span class="bh">{{viewSubmissionInfo.taskbh}}</span>
               </div>
               <div class="details_nr">
                   <div class="content" v-for="item in viewSubmissionInfo.img" :key="item.id">
                          <div class="title">{{item.text}}</div>
                          <el-image :src="item.img" style="width:220px; height: 400px"  fit="fill" lazy class="picture" :preview-src-list="submissionDetailsImageList"  @click="previewImage(viewSubmissionInfo.img)"></el-image>
                   </div>
               </div>
          </div>
    </el-dialog>


      <!-- 审核好评对话框 -->
    <el-dialog title="查看买手评价" :visible.sync="viewReviewsDialogVisible" width="50%" center>
          <div class="view_reviews">
                <div class="top">
                      <div class="title">好评类型</div>
                      <div class="content">{{viewReviewsInfo.text}}</div>
                </div>
                <div class="middle">
                      <div class="title">好评截图</div>
                      <div class="imglist" v-for="item in viewReviewsInfo.img" :key="item.id">
                             <el-image :src="item.img" style="width:220px; height: 400px"  fit="fill" lazy class="view_reviews_picture" :preview-src-list="viewReviewsImageList"  @click="viewReviewsImage(viewReviewsInfo.img)"></el-image>
                      </div>
                </div>
                <div class="bottom">
                        <el-button type="success" icon="el-icon-check" size="small" @click="toExamine(1)">通过审核</el-button>
                        <el-button type="warning" icon="el-icon-info" size="small" @click="toExamine(2)">审核不通过</el-button>
                        <el-button type="danger"  icon="el-icon-close" size="small" @click="viewReviewsDialogVisible = false">取消退出</el-button>
                </div>
          </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from  'vuex'
export default {
  data() {
    return {
      //获取任务列表的参数对象
      queryInfo: {
        // 当前的页数
        pagenum: 1,
        // 当前每一页显示多少条数据
        pagesize: 10
      },
      //店铺列表
      storeOptions: [
        {
          value: "1",
          label: "名望香业"
        },
        {
          value: "2",
          label: "谈香论道"
        },
        {
          value: "3",
          label: "春雪送信"
        },
        {
          value: "4",
          label: "闻香识味"
        },
        {
          value: "5",
          label: "香木阁主"
        }
      ],
      storevalue: "",
      //平台列表
      platformOptions: [
        {
          value: "1",
          label: "淘宝"
        },
        {
          value: "2",
          label: "京东"
        },
        {
          value: "3",
          label: "拼多多"
        },
        {
          value: "4",
          label: "抖音"
        }
      ],
      platformValue: "",
      //订单状态列表
      orderStatusOptions: [
        {
          value: "1",
          label: "全部"
        },
        {
          value: "2",
          label: "订单待提交"
        },
        {
          value: "3",
          label: "订单待确认"
        },
        {
          value: "4",
          label: "待上传快递"
        },
        {
          value: "5",
          label: "待上传好评"
        },
        {
          value: "6",
          label: "好评待确认"
        },
        {
          value: "7",
          label: "订单未通过审核"
        }
      ],
      orderStatusValue: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      //预览图片列表
      praiseImageList:[],
      //提交详情预览图片集合
      submissionDetailsImageList:[],
      //好评图片列表
      viewReviewsImageList:[],
      //控制查看评论对话框的显示与隐藏
      viewReviewsDialogVisible:false,
      //买家提交详情
      submissionDetailsDialogVisible:false,
      //待审核编号
      taskbh:'',

      //时间
      timeValue:[],
      //条件
      condition:''
    };
  },
  props:['otherInfo','advancePaymentInfo'],
   computed:{
     ...mapState({
         viewSubmissionInfo:state => state.advancePayment.viewSubmissionInfo,
         viewReviewsInfo:state => state.advancePayment.viewReviewsInfo
     })
  },
  methods:{
      //监听pagesize改变的事件
      handleSizeChange(newSize) {
          this.queryInfo.pagesize = newSize;
          this.queryInfo.pagenum = 1;
          this.$parent.$parent.changeClassification(1)
      },
    
      //监听页码值改变的事件
      handleCurrentChange(newPage) {
          this.queryInfo.pagenum = newPage;
          this.$parent.$parent.changeClassification(1)
      },

      //获取预览图片集
      getPraisePreviewImage(list){
             let imageList = []
             list.forEach(item => {
                  imageList.push(item.url)
             });
             this.praiseImageList = imageList
      },

      //拉黑接单者
      joinTheBlacklist(userid){
          // 打开提示弹窗
          this.$confirm('是否确认拉黑'+userid+'此买家账号？', '提示', {
               distinguishCancelAndClose: true,
               confirmButtonText: '拉黑',
               cancelButtonText: '取消',
               type: 'warning',
               center: true
          })
          .then(async () => {
               try{
                    await this.$store.dispatch('joinTheBlacklist',userid)
                    this.$message.success("已拉黑！");
                    this.$parent.$parent.changeClassification(1)
               }catch(error){
                    this.$message.error(error.message)
               }
          })
          .catch(action => {
          });
      },

       //查看提交详情
      async viewSubmissionDetails(id){
            try{
               await this.$store.dispatch('viewSubmissionDetails',id) 
               this.submissionDetailsDialogVisible = true
            }catch(error){
               this.$message.error(error.message)
            }           
      },

      //获取提交详情预览图片集合
      previewImage(list){
             let imageList = []
             list.forEach(item => {
                  imageList.push(item.img)
             });
             this.submissionDetailsImageList = imageList
      },

      //查看好评
      async viewReviews(id,taskbh){
          try{
               await this.$store.dispatch('viewReviews',id)
               this.taskbh = taskbh
               this.viewReviewsDialogVisible = true
          }catch(error){
               this.$message.error(error.message)
          }
      },

      //预览好评图片列表
      viewReviewsImage(list){
             let imageList = []
             list.forEach(item => {
                  imageList.push(item.img)
             });
             this.viewReviewsImageList = imageList
      },

      //审核是否通过
      async toExamine(pass){
          const { taskbh } = this
          try{
               await this.$store.dispatch('toExamine',{taskbh,pass})
               this.$message.success('审核成功！')
               this.$parent.$parent.changeClassification(1)
               this.taskbh = ''
               this.viewReviewsDialogVisible = false
          }catch(error){
               this.$message.error(error.message)
          }
      },

      //催评
     async urgeEvaluation(taskbh){
          try{
               await this.$store.dispatch('urgeEvaluation',taskbh)
               this.$message.success('已催评！')
          }catch(error){
               this.$message.error(error.message)
          }
      },

      //搜索
      search(){
           const {storevalue,timeValue,condition,orderStatusValue} = this
           let object = {}
           object.store = storevalue
           object.orderstatus = orderStatusValue
           object.key = condition
           if(this.timeValue != null){//解决clear存在的问题
                object.stime = this.timeValue[0]
                object.etime = this.timeValue[1]
           }else{
                this.timeValue = []
                object.stime = this.timeValue[0]
                object.etime = this.timeValue[1]
           }
           this.$parent.$parent.changeClassification(1,object)
      }
  }
};
</script>

<style lang="less" scoped>
.second_module {
  margin-top: 30px;
  .search_box {
    padding: 0 0 0 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .search_item {
      margin-right: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.operation_btns{
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
}

.submission_details{
     .details_bh{
          margin-bottom:10px;
          .bh{
              color:#FFAC1C
          }
     }
     .details_nr{
        display:flex;
        justify-content:flex-start; 
        align-items:center;
        flex-wrap:wrap;
        margin-top:20px;
       .content{
          display:flex;
          flex-direction:column;
          justify-content:flex-start;
          margin-right:15px;
          margin-bottom:30px;
          width:250px;
          .title{
              font-size:16px
          }
         .picture{
              // width: 50%;
              // height:50%;
          }
        }
     }
  
}

.view_reviews{
     .top{
          width:100%;
          display:flex;
          justify-content:flex-start;
          align-items:center;
          .title{
               width:100px;
          }
          .content{
              
          }
     }
     .middle{
          margin:20px 0;
          display:flex;
          justify-content:flex-start;
          align-items:center;
          .title{
               width:100px;
          }
          .imglist{
               display: flex;
               justify-self: start;
               align-items: center;
               flex-wrap: wrap;
               .view_reviews_picture{
                     margin-right: 8px;
                     margin-bottom:10px
               }
          }

     }
     .bottom{
            display: flex;
            justify-content: center;
            align-items: center;
     }
}

.kd {
  width: 200px;
}
.tips{
    color: #d40b35;
    margin-left: 20px;
    padding:0 10px 0;
}
.table{
    margin:30px 30px 20px;
}
.el-pagination{
   padding:0 30px;
   margin-bottom:30px;
}
</style>